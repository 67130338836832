<template>
	<v-list style="width: 100%; background-color: transparent" class="pl-2 py-0">
		<layer-list-item
			v-for="(element, index) in elementsList"
			v-model="self[element]"
			:title="elements[element]"
			:key="element"
			:border-bottom="index < elementsList.length - 1"
		></layer-list-item>
	</v-list>
</template>

<script>
import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';
import LayerListItem from './common/LayerListItem';
import {
	AIS_VESSEL_GROUP_ID,
	AIS_VESSEL_LAYER_ID,
	FILTER_AIS_VISIT_LAYER_ID,
	FILTER_SERVICE_LAYER_ID,
	FILTER_OTHER_VESSEL_LAYER_ID,
	FILTER_HIGH_FREQUENCY_ID
} from '@/components/operations/map/constants/layers';

const key = AIS_VESSEL_GROUP_ID;

export default {
	name: 'filteraislayerslist',

	components: {
		LayerListItem
	},

	props: {
		active: {
			type: Boolean,
			required: true
		}
	},

	data() {
		return {
			elements: {
				[FILTER_AIS_VISIT_LAYER_ID]: this.$t('operations.layers.filterObj.visit'),
				[FILTER_SERVICE_LAYER_ID]: this.$t('operations.layers.filterObj.service'),
				[FILTER_OTHER_VESSEL_LAYER_ID]: this.$t('operations.layers.filterObj.others'),
				[FILTER_HIGH_FREQUENCY_ID]: this.$t('operations.layers.filterObj.high_frequency')
			}
		};
	},

	computed: {
		self() {
			return this;
		},

		elementsList() {
			return Object.keys(this.elements);
		},

		anyActive() {
			return this.elementsList.map((key) => this[key]).some(Boolean);
		},

		[`${FILTER_AIS_VISIT_LAYER_ID}`]: {
			get() {
				return this.$store.getters.layers[key][FILTER_AIS_VISIT_LAYER_ID];
			},

			set(active) {
				this.setLayerGroupActive(FILTER_AIS_VISIT_LAYER_ID, active);
			}
		},

		[`${FILTER_SERVICE_LAYER_ID}`]: {
			get() {
				return this.$store.getters.layers[key][FILTER_SERVICE_LAYER_ID];
			},

			set(active) {
				this.setLayerGroupActive(FILTER_SERVICE_LAYER_ID, active);
			}
		},

		[`${FILTER_OTHER_VESSEL_LAYER_ID}`]: {
			get() {
				return this.$store.getters.layers[key][FILTER_OTHER_VESSEL_LAYER_ID];
			},

			set(active) {
				this.setLayerGroupActive(FILTER_OTHER_VESSEL_LAYER_ID, active);
			}
		},
		[`${FILTER_HIGH_FREQUENCY_ID}`]: {
			get() {
				return this.$store.getters.layers[key][FILTER_HIGH_FREQUENCY_ID];
			},

			set(active) {
				this.setLayerGroupActive(FILTER_HIGH_FREQUENCY_ID, active);
			}
		}
	},
	methods: {
		toggleAll() {
			this.elementsList.forEach((key) => (this[key] = this.active));
		},

		updateActive(active) {
			this.$emit('update:active', active);
		},

		setLayerGroupActive(subLayer, active) {
			this.$store.dispatch('setSubLayerGroupActive', { key, subLayer, active });

			if (active) {
				this.$emit('update:active', true);
				ol2map.setVisibility(AIS_VESSEL_LAYER_ID, active);
			}
		}
	},

	watch: {
		anyActive: {
			handler: 'updateActive',
			immediate: true
		}
	}
};
</script>
